
const firstNames = ['butter', 'sparkle', 'glitter', 'eva', 'flower', 'blossom', 'daisy', 'cherry', 'rainbow', 'twinkle', 'star', 'tap', 'red', 'happy'];
const secondNames = ['ice', 'ina', 'tail', 'hoof', 'eyes', 'ava', 'dash', 'trot', 'shine', 'ting', 'glow'];

const generateName = () => {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const secondName = secondNames[Math.floor(Math.random() * secondNames.length)];
  const fullName = firstName + '-' + secondName;
  const capitalisedName = fullName.charAt(0).toUpperCase() + fullName.slice(1);
  return capitalisedName;
}

export { generateName };
