import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { createMuiTheme }  from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { generateName } from '../utils/generator';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Jon Watson
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createMuiTheme({
  palette: {
    main: { main: '#06C88E' },
    secondary: { main: '#F3073A' },
    success: { main: '#FF5E07' },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  paper: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  button: {
    display: "flex",
    "justify-content": "center"
  },
  margin: {
    margin: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
  },
}));

export default function MainPage() {
  const classes = useStyles(theme);
  const [unicornName, setUnicornName] = useState('');

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="m">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h3" align="center" gutterBottom>
            Unicorn Name Generator
          </Typography>
          <div className={classes.button}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.margin}
              onClick={() => setUnicornName(generateName())}>
                Click me
              </Button> 
          </div>
            <Typography component="h3" variant="h4" align="center" gutterBottom>
              {unicornName}
            </Typography>
        </div>
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="m">
          <Typography variant="body1" color="textSecondary" align="center">Created with my daughter as her first piece of code.</Typography>
          <Typography variant="body1" color="textSecondary" align="center">
            {'Custom background created at '}
            <Link color="inherit" target='_blank' href='https://SVGBackgrounds.com'>
              SVGBackgrounds.com
            </Link>
            </Typography>
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}